
<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <modal
      v-if="showDeleteModal"
      size="md"
      title="Wiedervorlage entfernen"
      :closable="true"
      @close="showDeleteModal = !showDeleteModal"
    >
      <template #body>
        <p>Soll die Wiedervorlage wirklich entfernt werden?</p>
      </template>
      <template #footer>
        <button
          class="btn btn-danger float-right ml-2"
          @click="deleteResubmission()"
        >
          Entfernen
        </button>
        <button
          class="btn hover-gray-2 text-primary float-right"
          @click="showDeleteModal = !showDeleteModal"
        >
          Abbrechen
        </button>
      </template>
    </modal>
    <ca-header
      :loading="loading"
    >
      <template #heading>
        <div class="d-flex align-items-center">
          <h3 class="mb-0">
            {{ resubmission.subject }}
          </h3>
          <span
            class="badge ml-2"
            :class="getResubmitStatusClass(resubmission.status)"
          >
            {{ resubmitStatus[resubmission.status].human }}
          </span>
        </div>
      </template>
      <template #buttonSpace>
        <router-link
          class="btn btn-secondary text-primary"
          tag="button"
          :to="{name: 'ResubmitEdit', params: {resubmitId: resubmission._id}}"
        >
          <i class="far fa-pen" /> Bearbeiten
        </router-link>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div class="col-12 col-xl-8 col-xxl-6">
          <ca-card class="mb-3">
            <template #header>
              <div class="form-row">
                <div class="col">
                  <h4 class="mb-0">
                    Wiedervorlage
                  </h4>
                </div>
              </div>
            </template>
            <template #body>
              <div class="row mb-3">
                <!-- Vertragsnummer -->
                <div class="col-12 col-md-4">
                  Vertragsnummer
                </div>
                <div class="col-12 col-md-8">
                  <span v-if="resubmission.orderNumber">
                    {{ resubmission.orderNumber }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </div>
                <!-- Kennzeichen -->
                <div class="col-12 col-md-4">
                  Kennzeichen
                </div>
                <div class="col-12 col-md-8">
                  <span v-if="resubmission.order">
                    {{ resubmission.order.carData.kennzeichen }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </div>
                <!-- Fahrzeug -->
                <div class="col-12 col-md-4">
                  Fahrzeug
                </div>
                <div class="col-12 col-md-8">
                  <span v-if="resubmission.orderNumber">
                    {{ resubmission.order.classicCar.Hersteller }}  {{ resubmission.order.classicCar.Modellname }}  {{ resubmission.order.classicCar.Karosserie ? resubmission.order.classicCar.Karosserie : wkz[resubmission.order.wkz].human }}

                  </span>
                  <span v-else>
                    -
                  </span>
                </div>
                <!-- Kunde -->
                <div class="col-12 col-md-4">
                  Kunde
                </div>
                <div class="col-12 col-md-8">
                  <span v-if="resubmission.customer">
                    {{ resubmission.customer.name.VOR }} {{ resubmission.customer.name.NAM }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </div>
                <!-- Vermittler -->
                <div class="col-12 col-md-4">
                  Vermittler
                </div>
                <div class="col-12 col-md-8">
                  <span v-if="resubmission.vermittler">
                    {{ resubmission.vermittler.firstname }} {{ resubmission.vermittler.lastname }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </div>
                <!-- Kategorie -->
                <div class="col-12 col-md-4">
                  Kategorie
                </div>
                <div class="col-12 col-md-8">
                  <span v-if="resubmitCategory[resubmission.category]">
                    {{ resubmitCategory[resubmission.category].human }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </div>
                <!-- Betreff -->
                <div class="col-12 col-md-4">
                  Betreff
                </div>
                <div class="col-12 col-md-8">
                  {{ resubmission.subject }}
                </div>
                <!-- Grund -->
                <div class="col-12 col-md-4">
                  Gesprächsgrund
                </div>
                <div class="col-12 col-md-8">
                  <p
                    class="mb-0"
                    v-html="resubmission.reason"
                  />
                </div>
                <!-- Fällig -->
                <div class="col-12 col-md-4">
                  Fällig am
                </div>
                <div class="col-12 col-md-8">
                  {{ formatDate(resubmission.dueDate, 'DD.MM.YYYY HH:mm') }}
                </div>
                <!-- Bearbeiter -->
                <div class="col-12 col-md-4">
                  Bearbeiter
                </div>
                <div class="col-12 col-md-8">
                  <span v-if="resubmission.bearbeiter">
                    {{ resubmission.bearbeiter.firstname }} {{ resubmission.bearbeiter.lastname }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </div>
              </div>
            </template>
          </ca-card>
          <ca-card
            v-if="protocol"
            class="mb-3"
          >
            <template #header>
              <div class="form-row">
                <div class="col">
                  <h4 class="mb-0">
                    Protokoll
                  </h4>
                </div>
              </div>
            </template>
            <template #body>
              <div class="row">
                <div class="col-12 col-md-4">
                  Zusammenfassung
                </div>
                <div class="col-12 col-md-8">
                  <div
                    class="bg-gray-1 rounded p-3 mb-2"
                  >
                    <p v-html="protocol.summary" />
                  </div>
                </div>
              </div>
              <div
                v-if="protocol.result !== '<p></p>'"
                class="row py-3"
              >
                <div class="col-12 col-md-4">
                  Gesprächsergebnis
                </div>
                <div class="col-12 col-md-8">
                  <div
                    class="bg-gray-1 rounded p-3 mb-2"
                  >
                    <p v-html="protocol.result" />
                  </div>
                </div>
              </div>
              <div
                v-if="protocol.nextStep !== '<p></p>'"
                class="row pb-3"
              >
                <div class="col-12 col-md-4">
                  Nächster Schritt
                </div>
                <div class="col-12 col-md-8">
                  <div
                    class="bg-gray-1 rounded p-3 mb-2"
                  >
                    <p v-html="protocol.nextStep" />
                  </div>
                </div>
              </div>
              <div
                v-if="protocol.notes !== '<p></p>'"
                class="row"
              >
                <div class="col-12 col-md-4">
                  Notizen
                </div>
                <div class="col-12 col-md-8">
                  <div
                    class="bg-gray-1 rounded p-3 mb-2"
                  >
                    <p v-html="protocol.notes" />
                  </div>
                </div>
              </div>
            </template>
          </ca-card>
          <div class="d-flex align-items-center justify-content-end">
            <button
              v-if="!resubmission.isDone"
              class="btn btn-primary mr-2"
              @click="isDone"
            >
              <i class="far fa-check" /> Als erledigt markieren
            </button>
            <button
              class="btn btn-danger"
              @click="showDeleteModal = !showDeleteModal"
            >
              <i class="far fa-trash-alt mr-2" /> Wiedervorlage entfernen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import CaHeader from '@/components/Layout/Header'
import Modal from '@/components/Modal.vue'
import resubmitCategory from '@/resources/enums/resubmitCategory'
import resubmitStatus from '@/resources/enums/resubmitStatus'
import formatDate from '@/filters/formatDate.js'
import getResubmitStatusClass from '@/helpers/getResubmitStatusClass.js'
import wkz from '@/resources/enums/wkz.js'

export default {
  name: 'ResubmitDetail',
  components: {
    CaCard,
    CaHeader,
    Modal
  },
  data () {
    return {
      getResubmitStatusClass,
      formatDate,
      resubmitCategory,
      resubmitStatus,
      resubmission: null,
      protocol: null,
      subject: null,
      loading: false,
      showDeleteModal: false,
      wkz
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        this.resubmission = await this.$store.dispatch('resubmission/get', this.$route.params.resubmitId)
        this.protocol = await this.$store.dispatch('protocol/find', {
          query: {
            resubmitId: this.resubmission._id,
            $limit: 1
          }
        })
        this.protocol = this.protocol.data[0]
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.subject = this.resubmission.subject
        this.loading = false
      }
    },
    isDone () {
      this.resubmission.isDone = true
      this.resubmission.clone().save()
      this.fetchData()
    },
    async deleteResubmission () {
      if (this.protocol) {
        this.protocol.resubmitId = null
        await this.protocol.save()
      }
      await this.resubmission.remove()
      this.$router.push({ name: 'ResubmitList' })
    }
  }
}
</script>
