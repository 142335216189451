const resubmitStatus = require('@/resources/enums/resubmitStatus')

export default function getStatusClass (statusCode) {
  let statusClass = ''
  switch (statusCode) {
  case resubmitStatus.OVERDUE.api: statusClass = 'badge-danger'; break
  case resubmitStatus.DUE.api: statusClass = 'badge-warning'; break
  case resubmitStatus.SCHEDULED.api: statusClass = 'badge-primary'; break
  case resubmitStatus.DONE.api: statusClass = 'badge-success'; break
  }
  return statusClass
}
